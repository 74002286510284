import React from 'react'
import { Image } from '@beatgig/api-services'
import ResponsiveImage from '../responsive-image'
import { CloudinaryImage } from '@beatgig/helpers/cloudinary/types'
import Cloudinary from '@beatgig/helpers/cloudinary'
import defaultBreakpoints from '@beatgig/theme/breakpoints'
// import { ImageProps } from 'react-native'

type Props = {
  image?: Image
  /**
   * If unset, it will set the max size for each breakpoint.
   */
  sizes?: (number | string | CloudinaryImage)[]
} & Omit<React.ComponentProps<typeof ResponsiveImage>, 'sources' | 'source'>

export default function CloudinaryResponsiveImage(props: Props) {
  const {
    image,
    sizes = defaultBreakpoints,
    maxWidth,
    quality = 75,
    ...rest
  } = props

  const sources = sizes
    .map((size) => {
      if (!image) return
      if (typeof size === 'number') {
        return Cloudinary(image as Image, { width: size, quality })
      } else if (typeof size === 'object') {
        return Cloudinary(image as Image, { quality, ...size })
      }
    })
    .map((uri) => ({ uri }))

  return (
    <ResponsiveImage
      {...rest}
      maxWidth={maxWidth}
      // height={image?.height}
      // width={image?.width}
      sources={sources}
      source={{ uri: image?.url }}
      quality={quality}
    />
  )
}
