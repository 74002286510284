import { View as NativeView, StyleSheet, Platform } from 'react-native'
import { styled, useDripsyTheme } from 'dripsy'
import React, { useState, useContext } from 'react'
import { VerticalAlign } from '@beatgig/api-services'
import CloudinaryResponsiveImage from '@beatgig/components/cloudinary-responsive-image'
import Gradient from '@beatgig/design/components/gradient'
import { Skeleton } from 'moti/skeleton'
import { FastImage } from '@beatgig/components/fast-image'

const CoverImage = styled(FastImage)({
  // width: '100%',
  // ...StyleSheet.absoluteFillObject,
  // bottom: ['-50%', null, '-50%'],
  // bottom: ['-30%', '-40%', '-50%'], VERICAL ALIGN TOP
  // top: ['-30%', '-40%', '-50%'], VERICAL ALIGN BOTTOM
})

const LoadedContext = React.createContext((loaded: boolean) => {
  //
})

// enum VerticalAlign {
//   TOP,
//   CENTER,
//   BOTTOM,
// }

const MemoGradient = React.memo(function MemoGradient() {
  const { colors } = useDripsyTheme().theme
  return (
    <Gradient
      colors={[
        'transparent',
        `${colors?.background}70`,
        `${colors?.background}`,
      ]}
      sx={{
        px: 1,
        py: 6,
        mb: -1,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
      }}
      pointerEvents="none"
    />
  )
})

const verticalAlignAdjustment = ['-30%', '-40%', '-50%']

const Img = React.memo(function Img({
  verticalAlign = VerticalAlign.CENTER,
  sx = {},
  source,
  ...props
}: React.ComponentProps<typeof CoverImage> & {
  verticalAlign?: VerticalAlign
}) {
  let bottom: string[] | 0 = 0
  let top: string[] | 0 = 0
  if (verticalAlign === VerticalAlign.TOP) {
    // console.log('[artist-cover-img] IS TOP')
    bottom = verticalAlignAdjustment
  } else if (verticalAlign === VerticalAlign.BOTTOM) {
    top = verticalAlignAdjustment
    // console.log('[artist-cover-img] IS BOTTOM')
  } else {
    // console.log('[artist-cover-img] IS CENTER')
  }
  const onLoadImage = useContext(LoadedContext)
  return (
    <FastImage
      // {...props}
      onLoad={() => {
        onLoadImage(true)
      }}
      source={source}
      sx={{
        ...StyleSheet.absoluteFillObject,
        bottom,
        top,
        width: '100%',
        ...sx,
      }}
    />
  )
})

function CloudinaryImage({
  verticalAlign = VerticalAlign.CENTER,
  sx = {},
  ...props
}: React.ComponentProps<typeof CloudinaryResponsiveImage> & {
  verticalAlign: VerticalAlign
}) {
  let bottom: string[] | 'auto' = 'auto'
  let top: string[] | 'auto' = 'auto'
  if (verticalAlign === VerticalAlign.TOP) {
    bottom = verticalAlignAdjustment
  } else if (verticalAlign === VerticalAlign.BOTTOM) {
    top = verticalAlignAdjustment
  }

  return (
    <CloudinaryResponsiveImage
      sx={{
        width: '100%',
        ...StyleSheet.absoluteFillObject,
        bottom,
        top,
        ...sx,
      }}
      {...props}
    />
  )
}

const defaultHeightPercentage = ['90%', '70%', '70%', '50%', '40%']

const ImageContainer = styled(NativeView)(
  ({
    heightPercentage = defaultHeightPercentage,
  }: Pick<
    React.ComponentProps<typeof CoverImageContainer>,
    'heightPercentage'
  >) => ({
    width: '100%',
    height: 0,
    paddingBottom: heightPercentage,
    overflow: 'hidden',
  })
)

const CoverImageContainer = ({
  loading = false,
  children,
  heightPercentage,
}: {
  loading?: boolean
  children: React.ReactNode
  /*
   * To make a perfect square, set to "100%".
   */
  heightPercentage?: string | string[]
}) => {
  const [imageLoaded, setImageLoaded] = useState(Platform.OS === 'web')
  return (
    <LoadedContext.Provider value={setImageLoaded}>
      <Skeleton radius={0} show={loading || !imageLoaded}>
        <ImageContainer heightPercentage={heightPercentage}>
          {children}
        </ImageContainer>
      </Skeleton>
    </LoadedContext.Provider>
  )
}

export const ArtistCoverImage = {
  Image: Img,
  Container: CoverImageContainer,
  CloudinaryImage,
  Gradient: MemoGradient,
  height: defaultHeightPercentage,
}
