/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@theme-ui/core'
import { ResponsiveImageProps } from './types'
import Image from 'react-cool-img'
import last from 'lodash.last'
import defaultBreakpoints from '@beatgig/theme/breakpoints'

export default function ResponsiveImage(props: ResponsiveImageProps) {
  const {
    sx = {},
    source,
    sources = [],
    alt,
    sourceFallbackIndex = 0,
    maxWidth,
    resizeMode = 'cover',
    loading = 'lazy',
    priority,
    unoptimized,
    quality = 75,
    ...img
  } = props

  // https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
  const srcSet: string = sources
    ?.map(({ uri, width }, index) => {
      return `${uri} ${width ?? defaultBreakpoints[index]}w`
    })
    .join(', ')

  const finalSize =
    defaultBreakpoints[sources.length - 1] ?? last(defaultBreakpoints)

  let sizes: string = sources
    ?.map(({ width }, index) => {
      const px = `${defaultBreakpoints[index]}px`
      return `(max-width: ${px}) ${width ?? defaultBreakpoints[index]}px`
    })
    .join(', ')

  sizes = `${sizes}, ${maxWidth ?? finalSize}px`
  let imageHeight
  // @ts-ignore
  if (sx.top === 0 && sx.bottom === 0 && sx.position === 'absolute') {
    // annoying bug for IMGs that seems necessary to match RN styles
    // overall this is messy, but oh well.
    imageHeight = '100%'
  }

  return (
    <Image
      src={source?.uri ?? sources?.[sourceFallbackIndex]?.uri}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
      lazy={loading === 'lazy'}
      sx={{
        // RNW RESET taken from Dripsy
        alignItems: 'stretch',
        border: '0 solid black',
        boxSizing: 'border-box',
        display: 'flex',
        flexBasis: 'auto',
        flexDirection: 'column',
        flexShrink: 0,
        margin: 0,
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        zIndex: 0,
        objectFit: resizeMode,
        width: '100%',
        height: imageHeight,
        ...sx,
      }}
      {...(img as any)}
    />
  )
}
