export type WithoutTypename<T> = Omit<NonNullable<T>, '__typename'>

export function withoutTypename<Obj extends object | undefined>(
  obj: Obj
): Obj extends object ? Omit<NonNullable<Obj>, '__typename'> : undefined {
  // @ts-expect-error
  if ((obj as undefined) == undefined) return undefined

  // @ts-expect-error
  const { __typename, ...o } = obj

  // @ts-expect-error
  return o
}
