import { View } from 'dripsy'
import React, { ComponentPropsWithoutRef } from 'react'

import FieldLabel from './field-label'
// import Input from './text-input'
import Input from '@beatgig/design/components/input'
import { useFormikContext } from 'formik'
import { useFieldFast } from '../hooks/use-fast-field'
import ErrorText from './error'
import useIsFocused from '@beatgig/hooks/use-is-focused'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import GooglePlaces from '@beatgig/components/google-places'
import {
  GooglePlaceDetail,
  GooglePlaceData,
} from 'react-native-google-places-autocomplete'
import {
  Location,
  LocationFromPlaceIdDocument,
  LocationFromPlaceIdQuery,
  LocationFromPlaceIdQueryVariables,
} from '@beatgig/gql'
import { FieldName } from './types'
import { ScrollToField } from './scroll-to-field'

import * as Urql from 'urql'
import {
  WithoutTypename,
  withoutTypename,
} from '@beatgig/gql/types/without-typename'

export type AutoCompleteFieldProps<T extends object> = {
  //   name: FieldName<T> | PathsDot<T>
  // name: DottedPathsy<T>
  label?: string
  sx?: React.ComponentProps<typeof View>['sx']
  required?: boolean
  labelPosition?: 'top' | 'left' | 'right'
  /**
   * Optional, custom component to display the `label`, if specified.
   */
  Label?: React.ComponentType<any>
  // inputProps?: React.ComponentProps<typeof TextInput>
  names: {
    /**
     * Formik name path corresponding to the `place_id`
     */
    place_id: FieldName<T>
    /**
     * (Optional) Formik path corresponding to a `{ details: {...}, data: {...} }` object. If set, then the path given here will update. Set `fetchDetails` prop to `true`, otherwise `details` will be `null`.
     */
    googlePlace?: FieldName<T>
    /**
     * (Optional) Formik path corresponding to a `{ location }` object. If set, then the path given here will update. Set `fetchDetails` prop to `true`, otherwise `details` will be `null`.
     */
    location?: FieldName<T>
    /**
     * (Optional) Formik path corresponding to the value which indicates that there is an error. For example, `location.street_address` would cause this to error if that field isn't set, since you might asynchronously set the `location.street_address` via the `location` name prop.
     */
    error?: FieldName<T>
  }
  /**
   * If `true`, selecting an item will return only the `id` field for this place and set it as the form value.
   *
   * If `false`, `fetchDetails` will default to `true`.
   *
   * @default `true`
   *
   * @todo allow other values here too, besides id.
   */
  setPlaceIdOnly?: true
  placeholder?: string
} & Pick<
  React.ComponentProps<typeof GooglePlaces>,
  'fetchDetails' | 'textInputProps' | 'defaultText' | 'query'
>

export default function GooglePlacesField<T extends object>(
  props: AutoCompleteFieldProps<T>
) {
  const {
    label,
    sx = {},
    required = false,
    labelPosition = 'top',
    placeholder,
    textInputProps = {},
    names,
    setPlaceIdOnly = !names.googlePlace && !names.location,
    Label,
    ...placesProps
    // ...fieldProps
  } = props
  const nameProp = names.place_id
  // const { errors, handleBlur, handleChange } = useFormikContext<T>()
  const { ref, isHovered } = useAnimatedHover()
  // const input = React.useRef<typeof TextInput>()
  const [isFocused, focusBindings] = useIsFocused()
  const name = Array.isArray(nameProp) ? nameProp.join('.') : nameProp
  const [{ value }, { error, touched }, { setValue, handleBlur }] =
    useFieldFast(name as string)

  const [, { error: extraError }] = useFieldFast(
    (names.error as string) ?? '__noop_error'
  )

  const [, , { setValue: setGooglePlaceInfo }] = useFieldFast<{
    detail: GooglePlaceDetail | null
    data: GooglePlaceData
  }>((names.googlePlace as string) ?? '__noop_places')
  const [, , { setValue: setLocation }] = useFieldFast<
    WithoutTypename<Location>
  >((names.location as string) ?? '__noop_location')
  const { submitCount, setSubmitting } = useFormikContext()

  const hasErrored =
    (error != undefined || extraError != undefined) &&
    !!(touched || submitCount)

  const inputProps: Partial<ComponentPropsWithoutRef<typeof Input>> = {
    placeholder,
    ...textInputProps,
    isHovered,
    hasErrored,
    isFocused,
    label: labelPosition === 'left' ? label : undefined,
    labelRight: labelPosition === 'right' ? label : undefined,
    keyboardAppearance: 'dark',
    onBlur: handleBlur,
  }

  const urql = Urql.useClient()

  return (
    <View ref={ref} sx={sx}>
      <ScrollToField name={(names.error || name) as string} />
      {!!label && labelPosition === 'top' && (
        <FieldLabel
          isHovered={isHovered}
          hasErrored={hasErrored}
          isFocused={isFocused}
          required={required}
          as={Label}
        >
          {label}
        </FieldLabel>
      )}
      <GooglePlaces
        {...placesProps}
        textInputProps={inputProps}
        // fetchDetails={fetchDetails}
        onPress={async (data, detail) => {
          setValue(data.place_id)
          if (!setPlaceIdOnly) {
            if (names.googlePlace) {
              setGooglePlaceInfo({ data, detail })
            } else if (!names.location) {
              console.error(
                '[google-places-field] error: Missing names.googlePlace prop. Please set this name.'
              )
            }
            if (names.location) {
              setSubmitting(true)
              // FIXME gql we should change to useQuery({ pause: true })
              // this way we can display an error note below too...
              try {
                const locationQuery = await urql
                  .query<
                    LocationFromPlaceIdQuery,
                    LocationFromPlaceIdQueryVariables
                  >(LocationFromPlaceIdDocument, {
                    placeId: data.place_id,
                  })
                  .toPromise()

                if (locationQuery.data?.locationFromPlaceId) {
                  setLocation(
                    withoutTypename(locationQuery.data?.locationFromPlaceId)
                  )
                }
              } catch {
              } finally {
                setSubmitting(false)
              }
            }
          }
        }}
      />

      {hasErrored && !!(extraError?.trim() || error?.trim()) && (
        <ErrorText sx={{ zIndex: -2 }}>
          {extraError?.trim() || error?.trim()}
        </ErrorText>
      )}
    </View>
  )
}
