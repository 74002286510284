import { Location } from '@beatgig/gql'
import * as Gql from '@beatgig/gql'

type Fields = Partial<Record<keyof Location, boolean>>

const append = (currentValue: string, nextValue: string, appendBy = ', ') =>
  currentValue ? currentValue + appendBy + nextValue : nextValue

export default function locationToString(
  location?: Partial<Location & Gql.LocationInput & Gql.Location>,
  fields: Fields = {}
) {
  let description = ''
  if (location) {
    const { city, stateAbbr, countryName, streetAddress, postalCode } = location

    if (streetAddress && fields.streetAddress !== false) {
      description = streetAddress
    }
    if (city && fields.city !== false) {
      description = append(description, city)
    }
    if (stateAbbr) {
      if (fields.stateAbbr !== false) {
        description = `${description}, ${stateAbbr}`
      }
      if (postalCode && fields.postalCode !== false) {
        description = `${description} ${postalCode}`
      }
    } else if (countryName && fields.countryName !== false) {
      description = `${description}, ${city}, ${countryName}`
    }
  }
  return description
}
