import React, { useRef, useEffect, ComponentProps } from 'react'
import { StyleSheet, Platform } from 'react-native'
import {
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from 'react-native-google-places-autocomplete'
import Input from '@beatgig/design/components/input'
import { useDripsyTheme } from 'dripsy'
import { GOOGLE_API } from '@beatgig/constants/google-key'

// from basement/fredrick

type PlacesProps = ComponentProps<typeof GooglePlacesAutocomplete>

type Props = Partial<
  Pick<
    PlacesProps,
    'onFail' | 'onNotFound' | 'onTimeout' | 'minLength' | 'fetchDetails'
    // | 'textInputProps'
  > &
    Pick<
      PlacesProps,
      | 'onPress'
      | 'GooglePlacesDetailsQuery'
      | 'GooglePlacesSearchQuery'
      | 'query'
      | 'listEmptyComponent'
    >
> & {
  defaultText?: string
  textInputProps?: Partial<React.ComponentProps<typeof Input>> &
    Partial<PlacesProps['textInputProps']>
}

export default function GooglePlaces({
  minLength = 4,
  defaultText,
  query = {},
  fetchDetails = true,
  textInputProps = {},
  ...props
}: Props) {
  const { colors, radii, space } = useDripsyTheme().theme

  const {
    style,
    pressSx = {},
    sx = {},
    ...inputProps
  } = textInputProps as React.ComponentProps<typeof Input>

  const initialValue = useRef(defaultText)

  const ref = useRef<GooglePlacesAutocompleteRef>(null)

  useEffect(() => {
    if (initialValue.current) {
      console.log('[google-places][initialValue]', initialValue.current)
      ref.current?.setAddressText(initialValue.current)
    }
  }, [])

  return (
    <GooglePlacesAutocomplete
      placeholder="Enter an address, city or zip"
      isRowScrollable
      fetchDetails={fetchDetails}
      minLength={minLength}
      {...props}
      ref={ref}
      query={{
        key: GOOGLE_API,
        language: 'en', // language of the results
        ...query,
      }}
      enablePoweredByContainer={false}
      textInputProps={{
        InputComp: Input,
        ...inputProps,
        style: style ?? {
          width: '100%',
        },
        pressSx: {
          width: '100%',
          ...pressSx,
        },
        sx: {
          borderRadius: 3,
          ...sx,
        },
        multiline: Platform.OS !== 'web',
      }}
      styles={{
        textInputContainer: {
          width: '100%',
        },
        listView: {
          backgroundColor: colors?.background,
          borderWidth: 1,
          borderColor: colors?.border,
          borderRadius: radii?.[3],
          marginTop: space?.[2],
          overflow: 'hidden',
          ...Platform.select({
            web: {
              animationKeyframes: {
                from: {
                  opacity: 0,
                  transform: [{ translateY: -10 }],
                },
                to: {
                  opacity: 1,
                  transform: [{ translateY: 0 }],
                },
              },
              animationDuration: '0.25s',
            },
            default: {},
          }),
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          zIndex: 5,
        },
        row: {
          backgroundColor: colors?.background,
        },
        poweredContainer: {
          // display: 'none',
          backgroundColor: colors?.muted,
          borderColor: colors?.border,
        },
        description: {
          color: colors?.text,
        },
        separator: {
          backgroundColor: colors?.border,
        },
        powered: {
          backgroundColor: colors?.text,
        },
        loader: {
          backgroundColor: colors?.text,
          height: 100,
        },
      }}
      autoFillOnNotFound
      requestUrl={{
        url:
          'https://cors-proxy-pretty.herokuapp.com/https://maps.googleapis.com/maps/api',
        useOnPlatform: 'web',
      }} // this in only required for use on the web. See https://git.io/JflFv more for details.
    />
  )
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // padding: 10,
    // paddingTop: Constants.statusBarHeight + 10,
    // backgroundColor: '#ecf0f1',
  },
})
